/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "pages/finishedvehicle/search/FinVehicleSearchBarContainer";
import SearchBarContainerOpenSearch from "pages/finishedvehicle/search/FinVehicleSearchBarContainerOpenSearch";
import FiltersContainer from "pages/finishedvehicle/search/FinVehicleSearchFiltersContainer";
import FinVehicleSearchFiltersContainerOpenSearch from "pages/finishedvehicle/search/FinVehicleSearchFiltersContainerOpenSearch";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import { HoldTypesPanel } from "./components/organisms/FinishedVehicle.HoldTypesPanel.organism";
import { VinCategoryChartPanel } from "./components/organisms/FinishedVehicle.VinCategoryChartPanel.organism";
import WatchedVins from "./components/organisms/FinishedVehicle.WatchedVins.organism";
import { FinishedVehicleSavedSearchesPanel } from "./components/organisms/FinishedVehicle.SavedSearchesPanel.organism";

import {
  getExceptionData,
  getExceptionDataOs,
  getIconData,
  translateExceptionName,
} from "../utils/exceptions.utils";

import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { FinishedVehicleSavedSearchesPanelOpenSearch } from "./components/organisms/FinishedVehicle.SavedSearchesPanelOpenSearch.organism";

function FinVehicleDashboard(props) {
  const {
    entityCount,
    entityCountOs,
    entityCountLoading,
    entityDeliveredCount,
    entityDeliveredCountOs,
    entityDeliveredCountLoading,
    entityCompleteCount,
    entityCompleteCountLoading,
    exceptions,
    entityExceptionsCountOs,
    exceptionsLoading,
    vinHolds,
    vinHoldsLoading,
    setSearchFilter,
    clearSearchFilter,
    searchEntities,
    solutionId,
    exceptionTypes,
    resetSearchBar,
    clearSearchFilters,
    fetchExceptions,
    fetchEntityCount,
    fetchEntityDeliveredCount,
    fetchEntityCompleteCount,
    fetchHoldCounts,
    resetSavedSearch,
    fetchItssCount,
    itssCountData,
    itssCountDataIsLoading,
    showItssWidget,
    fetchSpotBuyCount,
    spotBuyCountData,
    spotBuyCountDataIsLoading,
    showSpotyBuyWidget,
    lifeCycleStateFilterOptions,
    isOpenSearchFeatureEnabled,
    resetSavedSearchOS,
    setSearchFilterOS,
    clearSearchFilterOS,
    searchEntitiesOS,
    resetSearchBarOS,
    clearSearchFiltersOS,
    fetchFinViewCounts,
    finviewCountsLoading,
    entityItssOs,
    entitySpotBuyOs,
  } = props;
  const { t } = useTranslation(["fv-dashboard", "labels", "exceptions"]);
  const [showFilters, setShowFilters] = useState(false);

  useTrackWithMixpanelOnce("Viewed Page: Finished Vehicle / Dashboard");

  useSetTitleOnMount(t("fv-dashboard:Finished Vehicle Dashboard"));

  useSetDescriptionOnMount(
    t(
      "fv-dashboard:All VINs for your organization and their related exceptions and holds",
    ),
  );

  let deliveredEntityCount = entityDeliveredCountOs;
  let completeEntityCount = entityDeliveredCountOs;

  if (!isOpenSearchFeatureEnabled) {
    deliveredEntityCount = entityDeliveredCount;
    completeEntityCount = entityCompleteCount;
  }

  const showCompleteCountInWidget = _.includes(
    lifeCycleStateFilterOptions,
    "Complete",
  );

  useEffect(
    () => {
      fetchHoldCounts(solutionId);
      if (!isOpenSearchFeatureEnabled) {
        resetSearchBar();
        clearSearchFilters();
        fetchExceptions(solutionId);
        fetchEntityCount(solutionId);
        fetchEntityCompleteCount(solutionId);
        fetchEntityDeliveredCount(solutionId);
        fetchHoldCounts(solutionId);
        fetchItssCount(solutionId);
        fetchSpotBuyCount(solutionId);
        resetSavedSearch();
        if (showCompleteCountInWidget) {
          fetchEntityCompleteCount(solutionId);
        }
      } else {
        resetSavedSearchOS();
        clearSearchFilterOS();
        fetchFinViewCounts(solutionId);
        resetSavedSearchOS();
        if (showCompleteCountInWidget) {
          fetchEntityCompleteCount(solutionId);
        }
      }
    },
    // We want this effect only to run once
    // eslint-disable-next-line
    [],
  );

  // handleClickException is for Exceptions and Delivered
  const handleClickException = (e) => {
    let searchableValue = [];
    if (e && e.name === t("fv-dashboard:Delivered")) {
      clearSearchFilter();
      setSearchFilter("lifeCycleState", ["Delivered"]);
      searchEntities(solutionId);
    } else if (e && e.name === t("fv-dashboard:Complete")) {
      clearSearchFilter();
      setSearchFilter("lifeCycleState", ["Complete"]);
      searchEntities(solutionId);
    } else {
      if (e && e.reasonCode) {
        searchableValue.push(
          _.find(exceptionTypes, { reasonCode: e.reasonCode }).id,
        );
      } else {
        exceptionTypes.forEach((exceptionType) => {
          searchableValue.push(exceptionType.id);
        });
      }

      clearSearchFilter();
      setSearchFilter("exception", searchableValue);
      setSearchFilter("lifeCycleState", ["Active"]);
      searchEntities(solutionId);
    }
  };

  const handleClickExceptionOS = (e) => {
    let searchableValue = [];
    if (e && e.name === t("fv-dashboard:Delivered")) {
      clearSearchFiltersOS();
      setSearchFilterOS("lifeCycleState", ["Delivered"]);
      searchEntitiesOS(solutionId);
    } else if (e && e.name === t("fv-dashboard:Complete")) {
      clearSearchFiltersOS();
      setSearchFilterOS("lifeCycleState", ["Complete"]);
      searchEntitiesOS(solutionId);
    } else {
      if (e && e.reasonCode) {
        searchableValue.push(
          _.find(exceptionTypes, { reasonCode: e.reasonCode }).id,
        );
      } else {
        exceptionTypes.forEach((exceptionType) => {
          searchableValue.push(exceptionType.id);
        });
      }

      clearSearchFiltersOS();
      setSearchFilterOS("vinExceptions", searchableValue);
      setSearchFilterOS("lifeCycleState", ["Active"]);
      setSearchFilterOS("activeSubStatus", "all_active");
      searchEntitiesOS(solutionId);
    }
  };

  // handleClickGraph is for Active
  const handleClickGraph = () => {
    clearSearchFilter();
    setSearchFilter("lifeCycleState", ["Active", "Prebuilt"]);
    searchEntities(solutionId);
  };

  const handleClickGraphOS = () => {
    clearSearchFiltersOS();
    setSearchFilterOS("lifeCycleState", ["Active"]);
    setSearchFilterOS("activeSubStatus", "all_active");
    searchEntitiesOS(solutionId);
  };

  const handleClickVinsInTransitStopShip = (datum) => {
    resetSearchBar();
    setSearchFilter("lifeCycleState", ["Active"]);
    setSearchFilter("groupCategory:ITSS", [datum.id]);
    searchEntities(solutionId);
  };

  const handleClickVinsInTransitStopShipOS = (datum) => {
    resetSearchBarOS();
    setSearchFilterOS("lifeCycleState", ["Active"]);
    setSearchFilterOS("activeSubStatus", "all_active");
    setSearchFilterOS("itssId", [datum.id]);
    searchEntitiesOS(solutionId);
  };

  const handleClickSpotBuy = (datum) => {
    resetSearchBar();
    setSearchFilter("lifeCycleState", ["Active"]);
    setSearchFilter("groupCategory:SB", [datum.id]);
    searchEntities(solutionId);
  };

  const handleClickSpotBuyOS = (datum) => {
    resetSearchBarOS();
    setSearchFilterOS("lifeCycleState", ["Active"]);
    setSearchFilterOS("activeSubStatus", "all_active");
    setSearchFilterOS("spotBuyAuth", [datum.id]);
    searchEntitiesOS(solutionId);
  };

  const data = useMemo(() => {
    if (isOpenSearchFeatureEnabled) {
      return getExceptionDataOs(
        entityExceptionsCountOs,
        exceptionTypes,
        entityCountOs,
        true,
      );
    } else {
      return getExceptionData(exceptions, exceptionTypes, entityCount, true);
    }
  }, [
    exceptions,
    exceptionTypes,
    entityCount,
    isOpenSearchFeatureEnabled,
    entityExceptionsCountOs,
    entityCountOs,
  ]);

  const deliveredWidget = {
    name: t("fv-dashboard:Delivered"),
    count: deliveredEntityCount || 0,
    icon: {
      ...getIconData("Delivered"),
    },
    tooltipText: t(
      "fv-dashboard:VINs delivered in the last [[[count]]] day or awaiting additional action",
      {
        count: 60,
      },
    ),
  };

  const completeWidget = {
    name: t("fv-dashboard:Complete"),
    count: completeEntityCount || 0,
    icon: {
      ...getIconData("Complete"),
    },
    tooltipText: t("fv-dashboard:VINs Completed in the last 60 days"),
  };

  // setup widgets based on available lifecycle states for org.
  let otherWidgets = [deliveredWidget];
  let isOtherWidgetsLoading = entityDeliveredCountLoading;

  if (showCompleteCountInWidget) {
    otherWidgets = [...otherWidgets, completeWidget];
    isOtherWidgetsLoading =
      entityDeliveredCountLoading || entityCompleteCountLoading;
    if (isOpenSearchFeatureEnabled) {
      isOtherWidgetsLoading = finviewCountsLoading;
    }
  }

  const exceptionGroups = [
    {
      title: t("labels:Exceptions"),
      includeInDonutChart: true,
      style: { flexGrow: 1 },
      exceptions: data.map((exception, i) => {
        return {
          ...exception,
          name: translateExceptionName(exception.name, t),
          icon: {
            ...getIconData(exception.name),
          },
        };
      }),
      isLoading: exceptionsLoading,
    },
    {
      title: t("labels:Other"),
      includeInDonutChart: false,
      exceptions: otherWidgets,
      isLoading: isOtherWidgetsLoading,
    },
  ];

  return (
    <Dashboard
      SearchBarContainer={
        isOpenSearchFeatureEnabled
          ? SearchBarContainerOpenSearch
          : SearchBarContainer
      }
      FiltersContainer={
        isOpenSearchFeatureEnabled
          ? FinVehicleSearchFiltersContainerOpenSearch
          : FiltersContainer
      }
      showFilters={showFilters}
      toggleShowFilters={(newShowFilters) => setShowFilters(newShowFilters)}
    >
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>{t("fv-dashboard:Summary View")}</Dashboard.Tab>
          <Dashboard.Tab>
            {t("fv-dashboard:My Finished Vehicle Homepage")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel
          styles={{
            [MediaQueries.smallAndUp]: {
              display: "flex",
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(6, 1fr)",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "1 / span 3",
                gridRow: "1 / span 2",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "1 / span 4",
                gridRow: "1 / span 1",
              },
            }}
          >
            <ExceptionsPanel
              title={t("fv-dashboard:Finished Vehicle VINs")}
              exceptionGroups={exceptionGroups}
              totalCount={
                isOpenSearchFeatureEnabled ? entityCountOs : entityCount
              }
              totalCountIsLoading={
                isOpenSearchFeatureEnabled
                  ? finviewCountsLoading
                  : entityCountLoading
              }
              totalCountLabel={t("fv-dashboard:Active")}
              handleClickException={
                isOpenSearchFeatureEnabled
                  ? handleClickExceptionOS
                  : handleClickException
              }
              handleClickGraph={
                isOpenSearchFeatureEnabled
                  ? handleClickGraphOS
                  : handleClickGraph
              }
              showNumberSeparator={true}
            />
          </div>
          <div
            css={{
              marginBottom: "20px",
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "4 / span 3",
                gridRow: "1 / span 2",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "5 / span 2",
                gridRow: "1 / span 2",
              },
            }}
          >
            <HoldTypesPanel
              vinHolds={vinHolds}
              vinHoldsLoading={vinHoldsLoading}
              title={t("fv-dashboard:Top Hold Types")}
              holdsLabel={t("fv-dashboard:Holds")}
              tableHeaderHoldTypeLabel={t("fv-dashboard:Hold Type")}
              tableHeaderVinsLabel={t("fv-dashboard:VINs")}
            />
          </div>
          {showItssWidget ? (
            <div
              css={{
                [MediaQueries.smallAndDown]: {
                  marginBottom: "20px",
                },
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "4 / auto",
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: "1 / span 4",
                  gridRow: "2 / auto",
                },
              }}
            >
              <VinCategoryChartPanel
                data={isOpenSearchFeatureEnabled ? entityItssOs : itssCountData}
                isLoading={
                  isOpenSearchFeatureEnabled
                    ? finviewCountsLoading
                    : itssCountDataIsLoading
                }
                onBarClick={
                  isOpenSearchFeatureEnabled
                    ? handleClickVinsInTransitStopShipOS
                    : handleClickVinsInTransitStopShip
                }
                title={t("fv-dashboard:VINs In Transit Stop Ship")}
                noDataLabel={t("fv-dashboard:No Data Available")}
                chartXAxisLabel={t("fv-dashboard:ITSS ID")}
                chartYAxisLabel={t("fv-dashboard:VIN Count")}
                chartTooltipSingularLabel={t("fv-dashboard:VIN")}
                chartTooltipPluralLabel={t("fv-dashboard:VINs")}
              />
            </div>
          ) : null}
          {showSpotyBuyWidget ? (
            <div
              css={{
                [MediaQueries.smallAndDown]: {
                  marginBottom: "20px",
                },
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "5 / auto",
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: "1 / span 4",
                  gridRow: "3 / auto",
                },
              }}
            >
              <VinCategoryChartPanel
                data={
                  isOpenSearchFeatureEnabled
                    ? entitySpotBuyOs
                    : spotBuyCountData
                }
                isLoading={
                  isOpenSearchFeatureEnabled
                    ? finviewCountsLoading
                    : spotBuyCountDataIsLoading
                }
                onBarClick={
                  isOpenSearchFeatureEnabled
                    ? handleClickSpotBuyOS
                    : handleClickSpotBuy
                }
                title={t("fv-dashboard:Spot Buy")}
                noDataLabel={t("fv-dashboard:No Data Available")}
                chartColor={Colors.exceptions.SPOT_BUY}
                chartXAxisLabel={t("fv-dashboard:Authorization Code")}
                chartYAxisLabel={t("fv-dashboard:VIN Count")}
                chartTooltipSingularLabel={t("fv-dashboard:VIN")}
                chartTooltipPluralLabel={t("fv-dashboard:VINs")}
              />
            </div>
          ) : null}
        </Dashboard.TabPanel>
        <Dashboard.TabPanel>
          {isOpenSearchFeatureEnabled ? (
            <FinishedVehicleSavedSearchesPanelOpenSearch />
          ) : (
            <FinishedVehicleSavedSearchesPanel />
          )}
          <WatchedVins
            isOpenSearchFeatureEnabled={isOpenSearchFeatureEnabled}
          />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
}

FinVehicleDashboard.propTypes = {
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  entityCount: PropTypes.number,
  entityCountOs: PropTypes.number,
  entityCountLoading: PropTypes.bool,
  entityDeliveredCount: PropTypes.number,
  entityDeliveredCountOs: PropTypes.number,
  entityDeliveredCountLoading: PropTypes.bool,
  entityExceptionsCountOs: PropTypes.number,
  entityCompleteCount: PropTypes.number,
  entityCompleteCountLoading: PropTypes.bool,
  exceptionTypes: PropTypes.array,
  exceptions: PropTypes.array,
  exceptionsLoading: PropTypes.bool,
  fetchEntityCount: PropTypes.func.isRequired,
  fetchEntityDeliveredCount: PropTypes.func.isRequired,
  fetchEntityCompleteCount: PropTypes.func.isRequired,
  fetchExceptions: PropTypes.func.isRequired,
  fetchHoldCounts: PropTypes.func.isRequired,
  holdTypes: PropTypes.any,
  resetSavedSearch: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  searchEntities: PropTypes.any,
  setSearchFilter: PropTypes.func.isRequired,
  solutionId: PropTypes.any,
  vinHolds: PropTypes.array,
  vinHoldsLoading: PropTypes.any,
  fetchItssCount: PropTypes.func,
  itssCountData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  itssCountDataIsLoading: PropTypes.bool,
  showItssWidget: PropTypes.bool,
  fetchSpotBuyCount: PropTypes.func,
  spotBuyCountData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  spotBuyCountDataIsLoading: PropTypes.bool,
  showSpotyBuyWidget: PropTypes.bool,
  lifeCycleStateFilterOptions: PropTypes.array,
  isOpenSearchFeatureEnabled: PropTypes.bool,
  resetSavedSearchOS: PropTypes.func,
  setSearchFilterOS: PropTypes.func,
  clearSearchFilterOS: PropTypes.func,
  searchEntitiesOS: PropTypes.func,
  resetSearchBarOS: PropTypes.func,
  clearSearchFiltersOS: PropTypes.func,
  fetchFinViewCounts: PropTypes.func,
  finviewCountsLoading: PropTypes.bool,
  entityItssOs: PropTypes.array,
  entitySpotBuyOs: PropTypes.array,
};

export default FinVehicleDashboard;
