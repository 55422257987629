import moment from "moment";
import _ from "lodash";
import qs from "qs";

import { OPEN_SEARCH_FILTERS } from "pages/finishedvehicle/search/FinVehicleSearchFilterDefsOpenSearch";
import apiUrl, { customerApiUrl } from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { PaginationType, SortType } from "components/search-bar/enums.utils";
import { SEARCH_CATEGORIES_OPEN_SEARCH } from "../search/FinVehicleSearchCategoryDefsOpenSearch";

const STORE_MOUNT_POINT = "fvSearchOpenSearch";

// H1-1594 Add lifecycle state to all VIN searches
// TODO: TBD
const addLifeCycleParam = (qs) => {
  if (qs.includes("lifeCycleState")) {
    return qs;
  }

  // Include the default lifeCycleState if not already specified by a filter
  return `${qs}&lifeCycleState=Active,Delivered,Complete,Prebuilt`;
};

const entitiesUrl = (solutionId, queryString) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/list?${queryString}`,
  );
};
const entitiesUrlWithoutOS = (solutionId, queryString) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(
    `/entity/solution/${solutionId}/entity?includeConfigurations=true&${qs}`,
  );
};

const batchSearchUrlWithoutBatchType = (solutionId, queryString) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(`/entity/solution/${solutionId}/batch-search?${qs}`);
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

/**
 * Helper function that will combine separate `groupCategory:<groupType>` params.
 *
 * e.g. `groupCategory:ITSS=100&groupCategory:SB=101` => `groupCategory=100,101`
 */
export const formatGroupCategoryParamInQueryString = (queryString) => {
  // Parse the query params
  const queryParams = qs.parse(queryString);

  // Pull out the separate `groupCategory` params.
  const categoriesForItss = queryParams["groupCategory:ITSS"];
  const categoriesForSpotBuy = queryParams["groupCategory:SB"];

  // Combine them in one list if both exist. Or set to one of the groupCategory params.
  let groupCategoryValue;

  if (categoriesForItss && categoriesForSpotBuy) {
    groupCategoryValue = categoriesForItss + "," + categoriesForSpotBuy;
  } else if (categoriesForItss) {
    groupCategoryValue = categoriesForItss;
  } else if (categoriesForSpotBuy) {
    groupCategoryValue = categoriesForSpotBuy;
  }

  // Remove the temporary query params.
  delete queryParams["groupCategory:ITSS"];
  delete queryParams["groupCategory:SB"];

  // Add the actual groupCategory param.
  queryParams.groupCategory = groupCategoryValue;

  return qs.stringify(queryParams);
};

const fetchSearch = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
  state,
  preventRedirect,
  requestBody = {},
) => {
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter && batchFilter.batch_type) {
    const batchValues = batchFilter.batch_list.split(",");

    requestBody = {
      ...requestBody,
      filter: {
        ...requestBody.filter,
        [batchFilter.batch_type]: {
          values: batchValues,
        },
      },
    };
    const url = entitiesUrl(solutionId, queryString);
    const config = axiosConfig();
    const axiosConfiguration = {
      ...config,
      method: "POST",
      url,
      data: requestBody,
      headers: {
        ...config.headers,
        Accept: "application/json",
      },
    };

    dispatch(duck.fetch(url, axiosConfiguration));

    if (!preventRedirect) {
      dispatch({ type: "VIN_SEARCH" });
    }
  } else {
    // Normal search GET
    const url = entitiesUrl(solutionId, queryString);
    const config = axiosConfig();
    const axiosConfiguration = {
      ...config,
      method: "POST",
      url,
      data: requestBody,
      headers: {
        ...config.headers,
        Accept: "application/json",
      },
    };

    // Fetch the search
    dispatch(duck.fetch(url, axiosConfiguration));

    // Prevent redirect if needed
    if (!preventRedirect) {
      dispatch({ type: "VIN_SEARCH" });
    }
  }
};

const SearchBarStateOpenSearch = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES_OPEN_SEARCH,
  OPEN_SEARCH_FILTERS,
  fetchSearch,
  [],
  {},
  20,
  PaginationType.OPEN_SEARCH,
  SortType.OPEN_SEARCH,
);

SearchBarStateOpenSearch.actionCreators = {
  ...SearchBarStateOpenSearch.actionCreators,
  exportSearch: _.partial(
    SearchBarStateOpenSearch.actionCreators.exportEntities,
    entitiesUrlWithoutOS,
    batchSearchUrlWithoutBatchType,
    { headers: { Accept: "text/csv;version=full" } },
    "fin-vehicle-search-results",
  ),
};

SearchBarStateOpenSearch.selectors = {
  ...SearchBarStateOpenSearch.selectors,
};

export default SearchBarStateOpenSearch;
