import Colors from "styles/colors";

const packageStatus = [
  "Created",
  "Available for Pickup",
  "In Route",
  "Delayed",
  "Delivered",
];

export const getLifeCycleStateTranslatedKeys = (lifecycleType, t) => {
  let lifecycleStateNameAndColor = null;

  switch (lifecycleType) {
    case "created/packaged":
      return (lifecycleStateNameAndColor = {
        lifecycleStateName: t("partview-dashboard:Created"),
        color: Colors.packageStatus.CREATED,
      });

    case "availableForPickup":
      return (lifecycleStateNameAndColor = {
        lifecycleStateName: t("partview-dashboard:Available for Pickup"),
        color: Colors.packageStatus.AVAILABLE_FOR_PICKUP,
      });

    case "inRoute":
      return (lifecycleStateNameAndColor = {
        lifecycleStateName: t("partview-dashboard:In Route"),
        color: Colors.packageStatus.IN_ROUTE,
      });

    case "delayed":
      return (lifecycleStateNameAndColor = {
        lifecycleStateName: t("partview-dashboard:Delayed"),
        color: Colors.packageStatus.DELAYED,
      });

    case "delivered":
      return (lifecycleStateNameAndColor = {
        lifecycleStateName: t("partview-dashboard:Delivered"),
        color: Colors.packageStatus.DELIVERED,
      });

    default:
      return lifecycleStateNameAndColor;
  }
};

export const getFormattedLifecycleObject = (lifecycleObject, t) => {
  let result;
  if (!lifecycleObject) {
    return [];
  } else {
    let totalActivePackages = 0;
    let totalPackages = 0;

    for (let item in lifecycleObject) {
      if (item === "delivered") {
        totalActivePackages += 0; //delivered count is not a part of active count
      } else {
        totalActivePackages += lifecycleObject[item];
      }
      totalPackages += lifecycleObject[item];
    }

    result = Object.entries(lifecycleObject).map((lifecycleCount) => {
      return {
        key:
          getLifeCycleStateTranslatedKeys(lifecycleCount[0], t)
            ?.lifecycleStateName ?? null,
        type:
          getLifeCycleStateTranslatedKeys(lifecycleCount[0], t)
            ?.lifecycleStateName ?? null,
        count: lifecycleCount[1],
        name:
          getLifeCycleStateTranslatedKeys(lifecycleCount[0], t)
            ?.lifecycleStateName ?? null,
        searchOption:
          getLifeCycleStateTranslatedKeys(lifecycleCount[0], t)
            ?.lifecycleStateName ?? null,
        fill:
          getLifeCycleStateTranslatedKeys(lifecycleCount[0], t)?.color ?? null,
        label: lifecycleCount[1] ?? null,
        totalActivePackages: totalActivePackages,
        totalPackages: totalPackages,
      };
    });
  }

  let sortedPackageStatus = result
    ? packageStatus
        .map((name) => result.find((key) => key.key === name))
        .filter((keys) => {
          return keys.type !== null;
        })
    : null;

  return sortedPackageStatus;
};
